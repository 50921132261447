import React, { useState } from 'react';
import StripeContainer from '../components/StripeContainer';
import "bootstrap/dist/css/bootstrap.min.css";

function Donation() {
    const [showItem, setShowItem] = useState(false);
  return (
    <div className='Donation'>
      <h1 className='my-4'>Faites un don !</h1>
      {showItem ? <StripeContainer/> : <> <h3>10€</h3> 
      <button onClick={() => setShowItem(true)}>Donation</button>
      </>}
    </div>
  )
}

export default Donation