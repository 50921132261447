import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './PaymentForm';
import React from 'react'

const PUBLIC_KEY = "pk_test_51OZi3aG9WYBDmZ6mK3cLD0xoiJ0YNHm9kEaLeZdjmU6lr9WJDaHvecKigYaLcM2NVidEt64czV7vDn9w8S3N2NeT00jyBSAKn4";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

export default function StripeContainer() {
  return (
    <Elements stripe={stripeTestPromise}>
        <PaymentForm/>
    </Elements>
  )
}
