import React from 'react'
import CardsParts from '../components/CardsParts'

function Articles() {
  return (
    <div>
        <CardsParts/>
    </div>
  )
}

export default Articles