import React from 'react'

function FollowParts() {
  return (
    <div className="text-center mt-2 p-2">
        <h1 className="follow-text">Suivez-nous sur les réseaux sociaux</h1>
    </div>
  )
}

export default FollowParts